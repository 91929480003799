import { createRouter, createWebHistory } from 'vue-router'
import Primaleva from '../views/Primalevaview.vue'

const routes = [
  {
    path: '/',
    name: 'primaleva',
    component: Primaleva
  },
  {
    path: '/massagehealing',
    name: 'massagehealing ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Massagehealing.vue')
  },
  {
    path: '/training',
    name: 'training',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Trainingview.vue')
  },
  {
    path: '/tider',
    name: 'tider',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "behandlingar" */ '../views/TimeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
