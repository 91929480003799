<template>
    <div class="container">
        <div>
   
        <h2 class="white pre shadow">VÄLGÖRANDE MASSAGE OCH HEALING I VÄSTRA GÖTEBORG</h2>
    
   <a href="#info"  >
      <img class="knappar" src="../assets/knappInfo.png">
    </a>
   
     
      
     <h3 class="white">BOKA TID: 0736-491087</h3>
    </div>
    </div>
   
   </template>
   <script>
       
   export default {
   name: 'HeaDer ',
   }
   
   </script>
   <style scoped>

   
   
   .container{
    background-image: url("@/assets/bgKlippa.jpg");  
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
  
   
   
  
   
   @media only screen and (max-width: 600px){

    .knappar{
    width: 30%;
   }  
    
  .container{
    height:600px;
  }
  h2, h3 {
 
  padding: 15px;
}
    

}
   
   </style>
   