<template>
  <div class="container">
    <h2 class="white">Tillsammans söker vi välmående</h2>
    <div class="flexer">
        <div class="ruta">
      <!-- <img class="minis" :src="pictjuren(texterna.temp.pic)" /> -->
      <img class="minis" src="../assets/till1.jpeg" />
        <h3 class="white">{{ texterna.tillsammans1.rubrik }}</h3>
        <p class="pre white"> {{ texterna.tillsammans1.text }}
         </p>
      </div>
      <a>
      <img class="knappar" src="../assets/knappPrimaleva.png" />
      </a>
      <div class="ruta">
        <img class="minis" src="../assets/till2.jpeg"  /> 
        <h3 class="white">{{ texterna.tillsammans2.rubrik }}</h3>

        <p class="pre white"> {{ texterna.tillsammans2.text }}
         </p>
      </div>
      <a>
      <img class="knappar" src="../assets/knappPrimaleva.png" />
      </a>
      
      <div class="ruta">
        <img class="mimis"  src="../assets/till3.jpeg" >
        <h3 class="white">{{ texterna.tillsammans3.rubrik }}</h3>
        <p class="pre white"> {{ texterna.tillsammans3.text }}
         </p>
      </div>
      <div id="kontakter"></div>
      <a>
      <img class="knappar" src="../assets/knappPrimaleva.png" />
      </a>
      
    </div>
  </div>
   
   </template>
   <script>
   import texter from "../assets/texter.json"
       
   export default {
    data(){ 
  return {
    texterna: texter,
  } 
}, 
    
   name: 'attTillsammans ',
   methods:{
    pictjuren(link) {
            return new URL("../assets/" + link, import.meta.url).href;
        },
   }
   }
   </script>
   <style scoped>
   
.knappar{
    position:relative;
    width: 80px;
    top:-60px; 
   }
   .flexer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
   }
   
   .container{
    background-image: url("@/assets/stenar.jpeg"); 
    margin-top: 0px;
    
   }
   .ruta{
    width: 300px;
    background-color: rgba(50,50,50,.75);
    
    height: 440px;  
  }
  .ruta p{
    text-align: center;
  }
  .minis{
    width:100%;
    
  }
  
   
   
  
   
   @media only screen and (max-width: 600px){
    
  .flexer{
    
    flex-direction: column;
    
  }
 
p {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

    

}
   
   </style>
   