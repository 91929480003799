<template>
  <div class="container">
    <div class="block">
      <div>
        <h1 class="white outline">MASSAGE & HEALING</h1>
      </div>
      
      <div class="miniblock">
        <p class="pre white outline"> {{ texterna.hm.text }}</p>
      </div>   
    </div>
  </div>
 
 </template> <script>
 import texter from "../assets/texter.json"
     
 export default {
  data(){ 
return {
  texterna: texter,
} 
}, 
  
 name: 'HeaDer ',
 }

 
 
 </script>
 
 <style scoped>
 
 .container{
  background-image: url("@/assets/stenar.jpeg");  
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .block{
  width:600px;
  background-color: #000;
  border-radius:10px; /* regular */
  opacity:0.7; /* Transparent Background 50% */
}
 
 .miniblock{
  
 }
 

 
 
   
 
 
 @media only screen and (max-width: 600px){
  
.container{
  height:600px;
}
h1 {
  font-size: 26px;
  text-align: center;
  padding: 15px;
}
h2, h3 {
padding: 15px;
}
  

}
 
 </style>
 