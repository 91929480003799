<template>
    <div class="container">
        <div>
   
        <h2 class="white">{{ texterna.t2.rubrik }}</h2>
        <p class="pre white"> {{ texterna.t2.text }}
         </p>
       
         

<iframe src="https://www.youtube.com/embed/ohvdcRYWj_s?si=mjNFiCUP0WLkXS0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

         <p class="pre white">Andra rörelsetips från bla instagram: SensingYoga med Mia, Soma by Linus, NaprapatMarcus, taichi.zidong.ins, naturalcure24h, meditationsguiden, balans.i.centrum… goodmove.se (skonsam dansgympa i Göteborg)</p>
</div>

</div>
   
   </template>
   <script>
   import texter from "../assets/texter.json"
       
   export default {
    data(){ 
  return {
    texterna: texter,
  } 
}, 
    
   name: 'HeaDer ',
   }

   
   
   </script>
   <style scoped>
   
   .container{
    background-color: sienna;   
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
  
   
   
   .knappar{
    width: 20%;
    
     
   }
   
   @media only screen and (max-width: 600px){
    
  .container{
    height:auto;
    
  }
p {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
    

}
   
   </style>
   