<template>
 <div class="top">

<img class="logga" src="../assets/primaleva_logga.png"> 
<!--
<nav class="viewBig">   
  
   <router-link to="/">
  Primaleva
  </router-link>
  
   <router-link to="/massagehealing">
   Massageh & Healing
  </router-link>
  
   <router-link to="/tid">
   Andlig/medial healing
  </router-link>
   
</nav>
--><!--
<div class="viewLittle">
<img class="hamburgare" src="../assets/hamburgermenu-150x150.png" @click="showlinks"> 

<div class="dropdown" v-show="showMeny" @click="closemeny">

  <div class="dropdown-content">
     
  
  <router-link class="bdown" to="/">
 Primaleva
 </router-link>

  <router-link class="bdown" to="/massagehealing">
    Massage & healing
 </router-link>
 <router-link class="bdown" to="/training">
    Träningsförslag
 </router-link>
 <hr>
  <router-link class="bdown" to="/lokal">
  Andlig/medial healing
 </router-link>
  
  </div>

  </div>
  </div>
-->



  </div>


</template>
<script>
  
export default {
name: 'HeaDer ',
data(){ 
  return {
    showMeny:false,
  } 
  

},
methods:{
  showlinks(){
    this.showMeny = !this.showMeny;
  },
  closemeny(){
    this.showMeny =false;
  }
}
}

</script>

<style scoped>


.logga {
float: left;
width:30%;
}
.viewLittle{
  display: none;
}
nav{
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  float: right;
  padding: 80px;
  font-size: 24px;
  
}

nav a {
  text-align: right;
  text-decoration: none;
  font-weight: bold;
  color:black;
}

nav a.router-link-exact-active {
  color: #325a81;
  
} 
.top{
  z-index: 1;
  top:0px;
  position: fixed;
  width: 100%;
  border: 0px;
  height:120px;
  background-color: rgba(200,200,200,.5);

  
}

@media only screen and (max-width: 600px) {
  .viewLittle{
    display: block;
  }
  .viewBig{
    display:none;
  }
  .logga {
    padding: 10px;
    padding-top: 20px;
    width:40%;
  
}
.hamburgare{
  padding:10px;
  width:30px;
  float:right
}
.d/* Dropdown Button */


/* Dropdown button on hover & focus */


/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: flex;
}

.dropdown-content {
  
  position:fixed;
  width:100%;
  top:100px;
  background-color: #f1f1f1;
  min-width: 200px;
  
  z-index: 1;
 
  
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  
}



.dropdown-content a.router-link-exact-active {
  color: white;
  background-color: #325a81;;
  
} 

}


  
</style>
