<template>
    <div class="container">
        <div class="ram">
        <h3>{{ texterna.information.rubrik }}</h3>
        <p class="pre"> {{ texterna.information.text }}</p>
       
        <img class="mimis"  src="../assets/till1.jpeg" >
       
    
      
    </div>
    </div>
   
   </template>
   <script>
   import texter from "../assets/texter.json"
       
   export default {
    data(){ 
  return {
    texterna: texter,
  } 
}, 
    
   name: 'HeaDer ',
   }

   
   
   </script>
   <style scoped>
   
   .container{
    background-color: white;   
    height: 400px;
   
  
 
    
   }
   .ram{
    column-gap: 25px;
    column-count: 2;
    margin: 40px;
   }
  
   
   
   .knappar{
    width: 20%;
    
     
   }
   
   @media only screen and (max-width: 600px){
    
  .container{
    height:auto;
    column-count: 1;
    
  }
  h3{
    padding-bottom: 0px;
    margin: 0px;
  }
p {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
.ram{
    
    column-count: 1;
    margin: 20px;
   } 

}
   
   </style>
   