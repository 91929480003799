<template>
  
  <Meny />
  <router-view />
</template>
<script>
import Meny from '@/components/Meny.vue'


  
  export default {
  name: 'HomeView',
  components: {
    Meny
  }


}
</script>

<style>
body{
 
  top: 0;
   height: 100%; 
   margin: 0;
   width: 100%;
  }
 

.center{
  justify-content: center;
}

.left{
  text-align:justify;
}
.bladL{
  background-image: url("@/assets/blad.png");
  background-repeat: no-repeat; 
}
.bladH{
  background-image: url("@/assets/bladh.png");
  background-repeat: no-repeat; 
  background-position: right; 

}
.blad2a{
  background-size: 15%;
  background-image: url("@/assets/blad2a.png");
  background-repeat: no-repeat; 
  background-position: bottom left;
  background-position-x: 10px;;
  
}
.blad2b{
  background-image: url("@/assets/blad2b.png");
  background-repeat: no-repeat; 
}
.boxL{
    background-color: bisque;
    padding:10px; 
    
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width:35%;
    margin-right:2%;
    display: inline-block;
  }
  .boxR{
    background-color: bisque;
    padding:10px; 
    
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    width:35%;
    margin-left:2%;
    display: inline-block;
  }
.floatR{
  float: right;
}
.floatL{
  float: left;
}
#app {
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;}

  h1{
    margin-top:0px;
    text-align: left;
    
    padding-left:40px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 3em;
  }
  .padda{
    padding-top: 400px;
  }

  .fixed {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
   
    color: #eeeeee;
    text-align: center;
  }
  .scroll {
    background-color: #ffffff;
    padding: 30px 70px;
    color: #666666;
    padding-left: 20%;
    padding-right: 20%;
  }
  .zero {
    
   background-image: url("@/assets/bgZero.png");
   width:100%;
   background-position:top;
   background-size:contain;
  
   
  
  }
  .one {
   background-image: url("@/assets/natur.jpeg");
   
  
  }
  .two {
   
    background-image: url(https://images.unsplash.com/photo-1505228395891-9a51e7e86bf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1190&q=80)
  }
  .three {
    background-image: url(https://images.unsplash.com/photo-1521165582142-eaf4bd77b3f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)
  }


</style>
